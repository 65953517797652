<template>
  <div v-if="dishesError">
    {{ dishesError }}
  </div>
  <div v-else>
    <div class="container">
      <PageIntro>
        <template v-slot:title>
          {{ $t("components.menus.masterDishList") }}
        </template>
        <template v-slot:description>
          {{ $t("components.menus.masterDishListDescription") }}
        </template>
        <template v-slot:actions>
          <BaseButton
            v-if="!$can('write:menu') && $can('read:menu')"
            size="large"
            variant="fill"
            :text="$t(`components.menus.proposeDish`)"
            class="hidden first:mr-4 lg:flex"
            @buttonClick="openModalCreateProposal"
          >
            <template #iconLeft>
              <IconPropose />
            </template>
          </BaseButton>
          <BaseButton
            v-if="$can('write:menu')"
            size="large"
            variant="fill"
            :text="$t(`components.menus.addDish`)"
            @buttonClick="redirectToCreateDish"
          >
            <template #iconLeft>
              <IconCreateNew />
            </template>
          </BaseButton>
        </template>
      </PageIntro>
    </div>
    <div
      :class="[
        'sm:container',
        { 'grid grid-cols-1 gap-12 lg:grid-cols-12': $can('write:menu') },
      ]"
    >
      <div class="lg:col-start-1 lg:col-end-9">
        <CourseFilter
          :courses="dishCourses"
          :active-course-uuid="activeCourseUuid"
          @updateSelectedCourse="updateSelectedCourse"
        />
        <div
          :class="[
            'col-gap-8 row-gap-1 lg:row-gap-2 grid grid-cols-1',
            { 'xl:grid-cols-2': !$can('write:menu') },
          ]"
        >
          <DishRow v-for="dish in dishes" :key="dish.uuid" :dish="dish" />
        </div>
      </div>
      <div v-if="$can('write:menu')" class="lg:col-start-9 lg:col-end-13">
        <CoreMenuForm />
      </div>
    </div>
    <transition name="fade">
      <ProposalModalCreate v-if="modalProposalCreateOpen" @close="closeModal" />
    </transition>
  </div>
</template>

<script>
import PageIntro from "@/components/menus/PageIntro";
import DishRow from "@/components/menus/DishRow";
import CourseFilter from "@/components/menus/CourseFilter";
import CoreMenuForm from "@/components/menus/CoreMenuForm";

import ProposalModalCreate from "@/components/menus/proposal/ProposalModalCreate";

import { mapActions, mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "MasterDishList",

  components: {
    PageIntro,
    DishRow,
    CourseFilter,
    ProposalModalCreate,
    CoreMenuForm,
  },

  data() {
    return {
      modalProposalCreateOpen: false,
    };
  },

  beforeRouteEnter(_to, _from, next) {
    Promise.all([
      store.dispatch("dish/fetchDishCourses"),
      store.dispatch("dish/fetchDishes"),
    ]).finally(() => {
      next();
    });
  },

  computed: {
    ...mapGetters("dish", [
      "dishCourses",
      "dishes",
      "dishesError",
      "activeCourseUuid",
    ]),
  },

  methods: {
    ...mapActions("dish", ["updateActiveCourseUuid"]),

    updateSelectedCourse(courseUuid) {
      this.updateActiveCourseUuid(courseUuid);
    },

    redirectToCreateDish() {
      this.$router.push({ name: "create-dish" });
    },

    openModalCreateProposal() {
      this.modalProposalCreateOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },

    closeModal() {
      this.modalProposalCreateOpen = false;
      document.body.classList.remove("overflow-y-hidden");
    },
  },
};
</script>
