<template>
  <div
    class="scrolling-touch disable-scrollbars flex overflow-x-scroll sm:mb-4"
  >
    <label
      v-for="course in courses"
      :key="course.uuid"
      class="whitespace-no-wrap flex cursor-pointer px-3 pb-5 pt-2 text-xs font-medium uppercase first:pl-5 last:pr-5 sm:mr-8 sm:px-0 md:py-3 md:pt-0"
    >
      <input
        type="radio"
        name="course"
        :value="course.uuid"
        class="absolute w-0 opacity-0"
        :checked="course.uuid == activeCourseUuid"
        @change="updateSelectedCourse(course.uuid)"
      />
      <span
        class="transition-color text-neutral-400 duration-200 hover:text-neutral-600"
      >
        <span>{{ course.name }}</span>
        <span> ({{ course.dishCount }})</span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    courses: {
      type: Array,
      required: true,
    },
    activeCourseUuid: {
      type: String,
      required: true,
    },
  },

  methods: {
    updateSelectedCourse(value) {
      this.$emit("updateSelectedCourse", value);
    },
  },
};
</script>

<style scoped>
input[type="radio"]:checked + span {
  @apply text-neutral-900;
}
@screen sm {
  label:first-child {
    @apply pl-0;
  }
  label:last-child {
    @apply pr-0;
  }
}
</style>
