<template>
  <div>
    <div
      class="mb-4 flex items-center justify-between px-5 font-medium sm:mb-6 sm:px-0"
    >
      <div>{{ $t("components.menus.menuSelection") }}</div>
      <button
        v-if="dishes && dishes.length"
        type="reset"
        class="focus:outline-none group inline-flex items-center"
        @click="clear"
      >
        <IconReset
          class="h-5 w-5 flex-shrink-0 text-neutral-400 transition-all duration-200 group-hover:text-neutral-600"
        />
        <span class="p-0 text-2xs font-bold uppercase text-neutral-800">
          {{ $t(`components.menus.emptySelection`) }}
        </span>
      </button>
    </div>
    <div
      v-if="organisations.length === 0 && !isEditing"
      class="border border-neutral-50 bg-white p-5 md:p-8"
    >
      <p class="mb-5 text-sm text-neutral-600">
        {{ $t("components.menus.coreMenusEmptyState") }}
      </p>
      <BaseButton
        size="medium"
        variant="outline"
        :text="$t(`pages.coreMenus.pageTitle`)"
        :full-width="true"
        @buttonClick="redirectToCoreMenus"
      >
      </BaseButton>
    </div>
    <div v-else class="border border-neutral-50 bg-white">
      <div v-if="formError">
        <ErrorAlert v-if="formError" :text="formError" />
      </div>
      <div
        v-for="course in courses"
        :key="course.uuid"
        class="border-b border-neutral-50 px-5 py-6 md:px-8"
      >
        <div class="flex items-center text-sm text-neutral-400">
          {{
            `${course.name} ${
              course.dishes && course.dishes.length ? course.dishes.length : 0
            }${course.limit ? `/${course.limit}` : ""}`
          }}
          <IconCheckbox
            v-if="course.dishes.length === course.limit"
            class="text-primary-400"
          />
        </div>
        <div
          v-for="(dish, index) in course.dishes"
          :key="index"
          class="group mt-4 flex"
        >
          <div
            class="relative mr-4 flex h-6 w-9 flex-shrink-0 items-center justify-center rounded-sm bg-neutral-50"
          >
            <img
              v-if="dish.coverImage && dish.coverImage.url"
              :src="dish.coverImage.url"
              class="absolute top-0 left-0 h-full w-full object-cover"
            />
            <IconBorettis v-else class="h-6 w-6 text-neutral-300" />
          </div>
          <div class="truncate flex-grow text-sm">
            {{ dish.name }}
          </div>
          <button
            class="focus:outline-none ml-2 flex-shrink-0 cursor-pointer text-neutral-200 transition-all duration-200 hover:text-danger-600 focus:text-danger-800 group-hover:opacity-100 sm:opacity-0"
            @click="deselectDish(dish)"
          >
            <IconDisband />
          </button>
        </div>
      </div>
      <div class="grid gap-4 px-5 py-6 md:px-8">
        <SelectField
          v-model="organisationUuid"
          :label="$t(`components.menus.setCoreMenuFor`)"
        >
          <option value="-1" selected disabled>
            {{
              $t(
                `components.userManagement.users.usersForm.partOfOrganisationPlaceholder`,
              )
            }}
          </option>
          <option
            v-for="organisation in organisations"
            :key="organisation.uuid"
            :value="organisation.uuid"
            :selected="organisationUuid === organisation.uuid"
          >
            {{ organisation.name }}
          </option>
          <option
            v-if="isEditing && currentCoreMenu.organisation"
            :value="currentCoreMenu.organisation.uuid"
            :selected="organisationUuid === currentCoreMenu.organisation.uuid"
          >
            {{ currentCoreMenu.organisation.name }}
          </option>
        </SelectField>
        <BaseButton
          size="large"
          variant="fill"
          :text="
            isEditing
              ? $t(`components.menus.updateCoreMenu`)
              : $t(`components.menus.saveCoreMenu`)
          "
          :full-width="true"
          :disabled="!isValid"
          @buttonClick="submit"
        >
        </BaseButton>
        <p v-if="!isValid" class="text-xs text-neutral-400">
          {{ $t("components.menus.coreMenuSaveInformation") }}
        </p>
      </div>
    </div>
    <BaseButton
      v-if="isEditing"
      size="large"
      variant="outline"
      :text="$t(`global.cancel`)"
      :full-width="true"
      class="mt-4"
      @buttonClick="cancel"
    >
    </BaseButton>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ErrorAlert from "@/components/common/ErrorAlert";

export default {
  name: "CoreMenuForm",

  components: {
    ErrorAlert,
  },

  computed: {
    ...mapGetters("dish", ["dishCourses"]),
    ...mapGetters("coreMenu", [
      "dishes",
      "courses",
      "currentCoreMenu",
      "organisations",
      "organisation",
      "isValid",
      "isEditing",
      "formError",
    ]),
    organisationUuid: {
      get() {
        return this.organisation;
      },
      set(value) {
        this.setOrganisation(value);
      },
    },
  },

  mounted() {
    this.fetchOrganisations();
  },

  methods: {
    ...mapActions("coreMenu", [
      "fetchOrganisations",
      "setOrganisation",
      "resetCoreMenu",
      "createCoreMenu",
      "updateCoreMenu",
      "resetDishes",
      "removeDish",
    ]),

    redirectToCoreMenus() {
      this.$router.push({
        name: "core-menus",
      });
    },

    submit() {
      if (this.isEditing) {
        this.updateCoreMenu();
      } else {
        this.createCoreMenu();
      }
    },

    clear() {
      this.resetDishes();
    },

    cancel() {
      this.resetCoreMenu();
    },

    deselectDish(dish) {
      this.removeDish(dish);
    },
  },
};
</script>
